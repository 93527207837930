import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { ACTIVE_PAGE } from "../components/nav/utils";

export default function PrivacyPolicyPage() {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.PRIVACYPOLICY}>
      <Seo
        title="Privacy Policy | Robotic Imaging"
        description='"Robotic Imaging is here to accelerate your architectural projects. Contact us to help streamline your process."'
      />

      <header className="site__header"></header>

      <main className="content">
        <div className="privacy-policy">
          <div className="privacy-policy-header">
            <h2>Robotic Imaging's Privacy Policy</h2>
          </div>
          <div className="privacy-policy-policy-container">
            <p>Last Updated: July 15, 2024</p>
            <p>
              Your privacy is important to us at Robotic Imaging, Inc. and its
              affiliates (collectively “Robotic Imaging,” “we,” “us,” or “our”),
              and we are committed to safeguarding, preserving, and respecting
              your privacy rights. This online privacy policy (the “Policy”)
              describes how we collect, use, disclose, and secure the personal
              information we gather about you through our website,
              https://www.roboticimaging.com/ (the “Site”), when you use our
              sites, applications, and when you otherwise interact with us
              (collectively, the “Services”).
            </p>
            <p>
              For purposes of this Policy, personal information means data that
              classifies as personal information, personal data, personally
              identifiable information, or similar terms under applicable data
              privacy and security laws and regulations. It does not include
              data excluded or exempted from those laws and regulations, such as
              aggregated, anonymized, or deidentified data. Nothing in this
              Policy will constitute an admission or evidence that any
              particular data privacy or information security law or regulation
              applies to Robotic Imaging generally or in any specific context.
            </p>
            <p>
              In providing our Services, Robotic Imaging may collect personal
              information on behalf of and as a service provider for third
              parties. This Policy does not govern any information we collect on
              behalf of third parties, and you should consult their privacy
              policies to become familiar with their data collection and usage
              practices.
            </p>
          </div>
          <div className="privacy-policy-policy-container">
            <h3>1. You Consent to This Policy</h3>
            <p>
              You should read this Policy carefully, so that you understand our
              privacy practices and our Terms &amp; Conditions. By accessing,
              browsing, downloading, or otherwise using the Services, you
              confirm that you have read, understood, and agreed with this
              Policy. If you do not agree to this Policy, you may not use the
              Services.
            </p>
            <p>
              This Policy and the Terms &amp; Conditions apply regardless of how
              the Services are accessed and will cover any technologies or
              devices by which we make the Services available to you.
            </p>
            <p>
              We may provide you with additional privacy notices where we
              believe it is appropriate to do so. It is important that you read
              this Policy together with any other privacy notice or terms we may
              provide on specific occasions, so that you are fully aware of how
              and why we are using your data. This Policy supplements these
              other notices and is not intended to override them.
            </p>
            <p>
              If you have any questions or concerns about our personal
              information policies or practices, you can contact us in the
              methods described in the “Contact Us” section below.
            </p>
            <h3>
              2. Types of Information We Collect and Purposes for Collection
            </h3>
            <p>
              We collect information you voluntarily provide directly to us,
              information that we collect automatically when you interact with
              the Services, and information collected from third parties.
            </p>
            <p>
              The categories of personal information that we collect and the
              purposes for which we collect that information are described
              below.
            </p>
            <ol type="a">
              <strong>
                <li>Analytics and Device Information</li>
              </strong>
              <p>
                We use cookies, beacons, pixels, tags or similar tools to
                collect information about how you interact with us, including
                your use of the Site, emails you receive from us, and otherwise.
                For more information, please view the “How We Use “Cookies” and
                Other Tracking Technologies” section below.
              </p>
              <p>
                We collect this information for the purposes of account creation
                and management, marketing and advertising, analytics and
                research, customer service, and website security and
                maintenance.
              </p>
              <p>
                We also collect this information for internal use, such as
                administering the Services, analyze trends, and identify
                problems with our Site.
              </p>
              <p>
                We share this information with analytics providers and third
                party advertisers.
              </p>
              <strong>
                <li>When You Create an Account with Us</li>
              </strong>
              <p>
                We collect certain information when you create an account with
                us, including first name, last name, email address, phone
                number, username, and password. This may also include business
                information including business name, size, and industry, when
                requesting partnerships.
              </p>
              <p>
                We collect this information when you provide it directly to us
                such as signing up for the Services, requesting information
                about Robotic Imaging services, creating an account, joining our
                network, requesting a partnership, or filling out a form.
              </p>
              <p>
                We collect this information for the purposes of advertising and
                marketing, analytics and research, and customer service.
              </p>
              <strong>
                <li>When You Make a Purchase with Us</li>
              </strong>
              <p>
                If you purchase our services, we collect certain payment and
                commercial information from you including name, address, phone
                number, third-party payment service provider-related
                information, debit or credit card information, purchase and
                participation history, or other payment processing information.
              </p>
              <p>
                We collect this information for the purposes of customer
                service, order fulfillment, marketing and advertising, and
                administering the Services.
              </p>
              <strong>
                <li>When You Respond to Surveys and Questionnaires</li>
              </strong>
              <p>
                When you respond to surveys and questionnaires, we collect
                information you provide to us when you respond to such marketing
                materials, promotions, contests, or other surveys.
              </p>
              <p>
                We collect this information for the purposes of marketing and
                advertising, analytics and research, and customer service.
              </p>
              <strong>
                <li>
                  Information We Collect When You Interact With Us on Social
                  Media
                </li>
              </strong>
              <p>
                When you engage with our accounts on social media, click on the
                social media icons on our Sites, or otherwise interact with us
                on a blog on a social media platform.
              </p>
              <p>
                Additionally, if you post, upload, comment, or otherwise submit
                content on the Services, we may collect certain information
                about you such as your name and email address and products you
                may have purchased. Be aware that as a default, any information
                you post on the Services, including without limitation reviews,
                comments, and text, may be available to and searchable by all
                users of the Services.
              </p>
              <p>
                We collect this information for the purposes of advertising and
                marketing, analytics and research, website security and
                maintenance, and customer service.
              </p>
              <strong>
                <li>
                  Other Ways We May Collect, Use, or Share the Information
                </li>
              </strong>
              <p>
                We may also reserve the right to collect, use or share personal
                information for the following purposes:
              </p>
              <ul>
                <li>
                  To Provide the Services. We will use your personal information
                  to provide the Services, including to improve operations and
                  offerings, and for security purposes.
                </li>
                <li>
                  To Present the Site. We will use personal information to
                  present our Site and its contents in a suitable and effective
                  manner for you and your device.
                </li>
                <li>
                  For Legal Purposes. We reserve the right to cooperate with
                  local, provincial, state, federal and international officials
                  in any investigation requiring either personal information or
                  reports about lawful or unlawful user activity on this site.
                  We also reserve the right to share your personal information
                  to establish, exercise, or defend our legal and property
                  rights, including providing information to others for the
                  purposes of fraud prevention. We may also share your personal
                  information with any person who we reasonably believe may
                  apply to a court or other competent authority for disclosure
                  of that personal information where, in our reasonable opinion,
                  such court or authority would be reasonably likely to order
                  disclosure of that personal information.
                  <li>
                    If we are ordered by a legal process or government to
                    provide data about one or more individual users, we will
                    notify those users of our compliance with that order, to the
                    extent permitted by law.
                  </li>
                </li>
                <li>
                  Business Partners and Affiliates. We may collect personal
                  information from and share personal information with our
                  business partners and affiliates, [including legal advisors
                  and financial advisors, co-branded partners,] and other third
                  parties. We may combine that information with other
                  information we collect about you, but we will always use the
                  information as described in this Policy. We may also share
                  information with our affiliates, subsidiaries, joint ventures
                  or other companies under common control.
                </li>
                <li>
                  Business Transactions or Mergers. We reserve the right to
                  share your personal information to third parties as part of
                  any potential business or asset sale, merger, acquisition,
                  investment, round of funding, or similar type of transaction.
                  Additionally, if we are entering into a corporate transaction
                  with a third party, we may receive personal information in
                  connection with the diligence. If we close a transaction, the
                  third party may transfer personal information, which we would
                  use as described in this Policy.
                </li>
                <li>
                  Bankruptcy or Insolvency. In the event of bankruptcy,
                  insolvency, or dissolution proceedings, we may share your
                  personal information with third parties as part of the sale or
                  reorganization process.
                </li>
                <li>
                  Customer Service. We will use your personal information to
                  provide you with customer service to troubleshoot issues, and
                  respond to requests, questions or comments.
                </li>
                <li>
                  Provide Information and Opportunities. We will use your
                  personal information to advertise opportunities, promotions,
                  products, events, or Services that we think may be of interest
                  to you.
                </li>
                <li>
                  Service Providers. We use service providers to perform various
                  functions on our behalf. Such service providers will be under
                  contractual obligations to safeguard your personal information
                  and only process it in accordance with our instructions, or as
                  otherwise permitted by applicable laws. We may also receive
                  personal information from service providers.
                </li>
              </ul>
            </ol>
          </div>
          <h3>3. How We Use “Cookies” and Other Tracking Technologies</h3>
          <p>
            We may send one or more cookies to your computer or other device. We
            may also use other similar technologies such as tracking pixels,
            tags, or similar tools when you visit our Services. These
            technologies can collect data regarding your operating system,
            browser type, device type, screen resolution, IP address, and other
            technical information, as well as navigation events and session
            information as you interact with our Services. This information
            allows us to understand how you use the Services.
          </p>
          <ol type="a">
            <strong>
              <li>Cookies</li>
            </strong>
            <p>
              Cookies are small files created by websites, including our
              Services, that reside on your computer’s hard drive and that store
              information about your use of a particular website. When you
              access our Services, we use cookies and other tracking
              technologies to:
            </p>
            <ul>
              <li>Estimate our audience size and usage patterns;</li>
              <li>
                Store information about your preferences, allowing us to
                customize our Services according to your individual needs;
              </li>
              <li>
                Contact you to provide you with information or services that you
                request from us;
              </li>
              <li>
                Advertise new content, events, and services that relate to your
                interests;
              </li>
              <li>
                Provide you with more personalized content that is most relevant
                to your interest areas; and
              </li>
              <li>Recognize when you return to our Services.</li>
            </ul>
            <p>
              We set some cookies ourselves and others are set by third parties.
              You can manage your cookie preferences as described in the
              “Managing Your Cookies” section below.
            </p>
            <strong>
              <li>Types of Cookies and Their Functions</li>
            </strong>
            <p>
              The following chart lists the different types of cookies that we
              and our service providers use on the Services, examples of who
              serves those cookies and links to the privacy notices and opt-out
              information of those cookie servers. Because the specific cookies
              we use may vary over time, as well as differ by the specific page
              you are browsing, the below chart is illustrative only.
            </p>
            <table>
              <tr>
                <th>Types of Cookies</th>
                <th>Purpose</th>
                <th>Who Serves (for example)</th>
              </tr>
              <tr>
                <td>Essential</td>
                <td>
                  These cookies are required for the operation of the Services
                  and enable you to move around the Services and use its
                  features.  Disabling these cookies can negatively impact the
                  performance of Services.
                </td>
                <td>
                  Robotic Imaging <br />{" "}
                  <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    style={{ color: "#ffb310" }}
                  >
                    Google
                  </a>
                </td>
              </tr>
              <tr>
                <td>Functionality</td>
                <td>
                  These cookies are used to recognize you when you return to the
                  Services.  This enables us to personalize content for you and
                  remember your preferences. These cookies also enable your
                  interactions with the Services such as emailing us and
                  customer support chat.
                </td>
                <td>
                  <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    style={{ color: "#ffb310" }}
                  >
                    Google
                  </a>
                </td>
              </tr>
              <tr>
                <td>Analytics, Performance, and Research</td>
                <td>
                  These cookies, beacons, and pixels allow us to analyze
                  activities on the Services.  They can be used to improve the
                  functioning of the Services.  For example, these cookies
                  recognize and count the number of visitors and see how they
                  move around the Services.  Analytics cookies also help us
                  measure the performance of our advertising campaigns to help
                  us improve them and to optimize the content on the Services
                  for those who engage with our advertising.
                </td>
                <td>
                  <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    style={{ color: "#ffb310" }}
                  >
                    Google
                  </a>
                </td>
              </tr>
              <tr>
                <td>Social Networking</td>
                <td>
                  These cookies are used to enable you to share pages and
                  content that you find interesting on our Services through
                  third-party social networking and other websites.  These
                  cookies may also be used for advertising purposes.
                </td>
                <td>
                  Meta <br />{" "}
                  <a
                    href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out?trk=microsites-frontend_legal_cookie-policy"
                    style={{ color: "#ffb310" }}
                  >
                    LinkedIn
                  </a>{" "}
                  <br />{" "}
                  <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    style={{ color: "#ffb310" }}
                  >
                    Google
                  </a>
                </td>
              </tr>
              <tr>
                <td>Advertising</td>
                <td>
                  These cookies and pixels are used to deliver relevant ads,
                  track ad campaign performance, or track email marketing.
                </td>
                <td>
                  <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    style={{ color: "#ffb310" }}
                  >
                    Google
                  </a>
                </td>
              </tr>
            </table>
            <p>
              We do not use analytical tools in a manner that discloses to third
              parties that a specific person viewed specific video materials.
            </p>
            <strong>
              <li>Cookie Retention Period</li>
            </strong>
            <p>
              Some cookies operate from the time you visit the Services until
              the end of that particular browsing session. These cookies, which
              are called “session cookies,” expire and are automatically deleted
              when you close your internet browser.
            </p>
            <p>
              Some cookies will stay on your device between browsing sessions
              and will not expire or automatically delete when you close your
              internet browser. These cookies are called “persistent cookies”
              and the length of time they will remain on your device will vary
              from cookie to cookie. Persistent cookies are used for a number of
              purposes, such as storing your preferences so that they are
              available for your next visit and to keep a more accurate account
              of how often you visit the Services, how your use of the Services
              may change over time, and the effectiveness of advertising
              efforts.
            </p>
            <strong>
              <li>Managing Your Cookies</li>
            </strong>
            <p>
              It may be possible to block cookies by changing your internet
              browser settings to refuse all or some cookies. If you choose to
              block all cookies (including essential cookies), you may not be
              able to access all or parts of the Services.
            </p>
            <p>
              You can find out more about cookies and how to manage them by
              visiting{" "}
              <a
                href="https://ico.org.uk/for-
            the-public/online/cookies/"
                style={{ color: "#ffb310" }}
              >
                https://ico.org.uk/for- the-public/online/cookies/
              </a>
              .
            </p>
            <strong>
              <li>Site Response to “Do Not Track” Signals</li>
              <p>
                At this time, our Site does not respond differently based on a
                user’s Do Not Track signal.
              </p>
            </strong>
          </ol>
          <h3>4. Nevada Users’ Rights</h3>
          <p>
            Under Nevada law, certain Nevada consumers may opt out of the sale
            of certain personally identifiable information for monetary
            consideration. We do not sell personally identifiable information
            under Nevada law.
          </p>
          <p>
            If you are a Nevada resident who has purchased or leased goods or
            services from us, you may (A) submit a request to opt out of any
            sales, and/or (B) request changes to your “covered information” by
            emailing us at{" "}
            <a
              href="mailto:info@roboticimaging.com"
              style={{ color: "#ffb310" }}
            >
              info@roboticimaging.com
            </a>
            . Please note we will take reasonable steps to verify your identity
            and the authenticity of the request.
          </p>
          <h3>5. Advertising and Marketing Choices</h3>
          <p>
            We respect your rights in how your personal information is used and
            shared. We may communicate with you via email, telephone, postal
            mail, and/or your mobile device about our products and services. If
            at any time you would like to unsubscribe from receiving future
            marketing emails, you can email us at{" "}
            <a
              href="mailto:info@roboticimaging.com"
              style={{ color: "#ffb310" }}
            >
              info@roboticimaging.com
            </a>{" "}
            or follow the instructions at the bottom of each applicable email,
            and we will promptly remove you from those marketing correspondences
            that you choose to no longer receive. Please note, however, that we
            may still need to contact you regarding other matters.
          </p>
          <h3>6. How Long Your Personal Information Is Kept</h3>
          <p>
            We will retain your personal information until the personal
            information is no longer necessary to accomplish the purpose for
            which it was provided. We may retain your personal information for
            longer periods for specific purposes to the extent that we are
            obliged to do so in accordance with applicable laws and regulations,
            to protect you, other people, and us from fraud, abuse, an
            unauthorized access, as necessary to protect our legal rights, or
            for certain business requirements.
          </p>
          <p>
            We will delete your personal information when it is no longer
            necessary for the purpose for which it was collected, or upon your
            request, subject to exceptions as discussed in this Policy or under
            applicable law, contract, or regulation.
          </p>
          <p>
            You can access and update certain personal information we hold about
            you at any time by logging into your account via the Services.
          </p>
          <h3>7. Our Commitment to Data Security</h3>
          <p>
            The security of your personal information is important to us. We
            take various reasonable organizational, administrative, and
            technical measures to protect your personal information from
            unauthorized access, disclosure, alteration, or destruction. If
            required by law to do so, we will notify you and/or the relevant
            supervisory authority in the event of a data breach.
          </p>
          <p>
            However, we cannot and do not guarantee complete security, as it
            does not exist on the internet.
          </p>
          <h3>8. Where Your Personal Information Is Held</h3>
          <p>
            We process personal information on our servers in the United States
            of America, and may do so in other countries. If you use our
            Services or otherwise provide us with information from outside of
            the United States, you expressly consent to the transfer of your
            data to the United States, the processing of your data in the United
            States, and the storage of your data in the United States.
          </p>
          <h3>9. Third Party Links</h3>
          <p>
            Our Services may contain links to third-party websites. When we
            provide links, we do so only as a convenience and we are not
            responsible for any content of any third-party website or any links
            contained within. It is important to note that this Policy only
            applies to our Services. We are not responsible and assume no
            responsibility for any personal information collected, stored, or
            used by any third party as a result of you visiting third-party
            websites. We also advise that you carefully read the privacy notice
            of any third-party websites you choose to visit.
          </p>
          <h3>10. Children’s Privacy</h3>
          <p>
            Protecting the privacy of the very young is especially important.
            Our Services are not intended for children under 13 years of age,
            and we do not knowingly collect personal information from children
            under 13. In the event that we learn that we have collected personal
            information from a child under age 13 without verification or
            parental consent, we will immediately delete that information. If
            you believe that we might have any information from or about a child
            under 13, please contact us using the information provided in the
            “Contact Us” section below.
          </p>
          <h3>11. Policy Changes</h3>
          <p>
            This Policy may change from time to time. If we need to change this
            Policy at some point in the future, we will post any changes on this
            page. If we make a significant or material change to this Policy we
            will notify you via email. You should check these terms when you use
            the Site. Your continued use of the Services constitutes acceptance
            of the most current version of this Policy.
          </p>
          <h3>12. Contact Us</h3>
          <p>
            If you have any questions about this Policy, please contact us by
            email at{" "}
            <a
              href="mailto:info@roboticimaging.com"
              style={{ color: "#ffb310" }}
            >
              info@roboticimaging.com
            </a>{" "}
            , via phone at{" "}
            <a href="tel:12679691153" style={{ color: "#ffb310" }}>
              +1 267-969-1153
            </a>
            , or at 2401 Walnut St, Suite 101, Philadelphia, PA 19103.
          </p>
        </div>
      </main>
    </SiteWrapper>
  );
}
